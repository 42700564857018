import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import { Box, DataGrid, DateField, FormControl, GridColumns, GridToolbar, InputLabel, List, TextField, Typography, debounce, styled, useDataGrid } from "@pankod/refine-mui";
import { IBooking, ICatalog } from "interfaces";
import Details from "./details";
import ReservationStatus from "components/details/reservationStatus";
import { formatNumberPrice, formatTimeRangeString24 } from "utils";
import Actions from "../../components/list/actions";

const CellLabel = styled(Typography)(() => ({
  fontSize: '14.16px',
  lineHeight: '56.64px'
}));

export const BookingsList: React.FC = (props: any) => {
  const t = useTranslate();
  const { dataGridProps } = useDataGrid<ICatalog>();

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [selectedReservation, setSelectedReservation] = useState<IBooking | undefined>(undefined);

  const filterOptions = [
    {
      label: 'Es igual a',
      value: 'equals',
      getApplyFilterFn: (filterItem: any) => {
        console.log(filterItem)
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value === filterItem.value;
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
    {
      label: 'Contiene',
      value: 'contains',
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value.includes(filterItem.value);
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
  ]

  const columns = React.useMemo<GridColumns<IBooking>>(
    () => [
      {
        field: "incrementId",
        headerName: t("bookings.fields.id"),
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "id",
        headerName: t("bookings.fields.confirmationNumber"),
        minWidth: 230,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "formattedStatus",
        headerName: t("bookings.fields.eventStatus"),
        renderCell: function render({ row }) {
          return <ReservationStatus
              reservationStatus={row.status}
              sx={{
                fontSize: '14.16px'
              }}
          ></ReservationStatus>;
        },
        minWidth: 175,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "hostName",
        headerName: t("bookings.fields.host"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.hostName}</CellLabel>;
        },
        minWidth: 137,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "guestName",
        headerName: t("bookings.fields.guest"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.guestName}</CellLabel>;
        },
        minWidth: 145,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "date",
        headerName: t("bookings.fields.date"),
        renderCell: function render({ row }) {
          return <DateField value={row.date.substring(0,10)} format="DD/MM/YYYY"  />;
        },
        minWidth: 155,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "time",
        headerName: t("bookings.fields.time"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatTimeRangeString24(row.time)}</CellLabel>;
        },
        minWidth: 187,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "remainingDays",
        headerName: t("bookings.fields.remainingDays"),
        renderCell: function render({ row }) {
          //const remainDays = moment(row.date).diff(moment(), 'days');
          return <CellLabel>{row.remainingDays} {t("bookings.remainingDays")}</CellLabel>;
        },
        minWidth: 239,
        flex: 0.2,
        filterable: false,
        // filterOperators: filterOptions
      },
      {
        field: "advertisementName",
        headerName: t("bookings.fields.name"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.advertisementName}</CellLabel>;
        },
        minWidth: 250,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "people",
        headerName: t("bookings.fields.people"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.people} {t("bookings.people")}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "total",
        headerName: t("bookings.fields.total"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.total, true)}</CellLabel>;
        },
        minWidth: 150,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "guaranteeDeposit",
        headerName: t("finances.fields.guaranteeDeposit"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.guaranteeDeposit, true)}</CellLabel>;
        },
        minWidth: 155,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "grandTotal",
        headerName: t("finances.fields.totalAmountToPaid"),
        renderCell: function render({ row }) {
          return <CellLabel>{formatNumberPrice(row.grandTotal, true)}</CellLabel>;
        },
        minWidth: 175,
        flex: 0.2,
        filterable: false,
        // filterOperators: filterOptions
      },
      {
        field: "advertisementAddress",
        headerName: t("bookings.fields.address"),
        renderCell: function render({ row }) {
          return <CellLabel>{row.advertisementAddress}</CellLabel>;
        },
        minWidth: 500,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
    ],
    [t]
  );

  return (
    <List>
      <Box sx={{position: 'relative'}}>
        <DataGrid 
          {...dataGridProps} 
          columns={columns} 
          components={{
            Toolbar: GridToolbar,
          }}
          autoHeight
        />
        <Actions
          reservations={dataGridProps.rows}
          setOpenSidebar={setOpenSidebar}
          setSelectedReservation={setSelectedReservation}
        ></Actions>
      </Box>
      <Details
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        reservation={selectedReservation}
      ></Details>
    </List>
  );
};