import React, { useEffect, useRef, useState } from "react";
import { useRouterContext, useTranslate } from "@pankod/refine-core";
import { BooleanField, Box, DataGrid, DateField, EditButton, ShowButton, GridColumns, GridToolbar, List, useDataGrid, Button, debounce, FormControl, InputLabel, TextField } from "@pankod/refine-mui";
import { IAdvertisement } from "interfaces";
import { Check, Close, VisibilityOutlined } from "@mui/icons-material";
import paths from "constants/paths";

export const AdvertisementList: React.FC = () => {
  const t = useTranslate();
  const { Link } = useRouterContext();

  const { dataGridProps } = useDataGrid<IAdvertisement>();

  const filterOptions = [
    {
      label: 'Es igual a',
      value: 'equals',
      getApplyFilterFn: (filterItem: any) => {
        console.log(filterItem)
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value === filterItem.value;
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
    {
      label: 'Contiene',
      value: 'contains',
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value.includes(filterItem.value);
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
  ]

  const columns = React.useMemo<GridColumns<IAdvertisement>>(
    () => [
      {
        field: "name",
        headerName: t("advertisements.fields.name"),
        renderCell: function render({ row }) {
          return <p>{row.name}</p>;
        },
        minWidth: 100,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "host",
        headerName: t("advertisements.fields.host"),
        maxWidth: 300,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "fullAddress",
        headerName: t("advertisements.fields.address"),
        renderCell: function render({ row }) {
          return <p>{row.fullAddress}</p>;
        },
        minWidth: 100,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "status",
        headerName: t("advertisements.fields.status"),
        maxWidth: 100,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "verificationStamp",
        headerName: t("advertisements.fields.verificationStamp"),
        renderCell: function render({ row }) {
            return <BooleanField
              value={row.verificationStamp}
              trueIcon={<Check />}
              falseIcon={<Close />}
            />
        },
        valueGetter: (params) => {
          return params.row.verificationStamp === false ? "No" : "Si";
        },
        maxWidth: 200,
        flex: 0.2,
        filterable: false,
        // filterOperators: filterOptions
      },
      {
        field: "guaranteeStamp",
        headerName: t("advertisements.fields.guaranteeStamp"),
        renderCell: function render({ row }) {
            return <BooleanField
              value={row.guaranteeStamp}
              trueIcon={<Check />}
              falseIcon={<Close />}
            />
        },
        valueGetter: (params) => {
          return params.row.guaranteeStamp === false ? "No" : "Si";
        },
        maxWidth: 200,
        flex: 0.2,
        filterable: false,
        // filterOperators: filterOptions
      },
      {
        field: "published",
        headerName: t("advertisements.fields.published"),
        renderCell: function render({ row }) {
            return <BooleanField
              value={row.published}
              trueIcon={<Check />}
              falseIcon={<Close />}
            />
          return '';
        },
        valueGetter: (params) => {
          return params.row.published === false ? "No" : "Si";
        },
        maxWidth: 200,
        flex: 0.2,
        filterable: false,
        // filterOperators: filterOptions
      },
      {
        field: "updatedAt",
        headerName: t("advertisements.fields.updatedAt"),
        renderCell: function render({ row }) {
          return <DateField value={row.updatedAt} format="DD/MM/YYYY H:mm" />;
        },
        maxWidth: 170,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "row" }}
              autoComplete="off"
            >
              <Link
                to={ paths.adDetails + '/' + row.id}
              >
                <Button
                  title={t("advertisements.fields.preview")}
                  sx={{ minWidth: 0 }}
                >
                  <VisibilityOutlined fontSize="small"/>
                </Button>
              </Link>
              {<EditButton hideText recordItemId={row.id} />}
            </Box>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid 
      {...dataGridProps} 
      columns={columns} 
      components={{
        Toolbar: GridToolbar,
      }}
      autoHeight
      />
    </List>
  );
};