import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "@pankod/refine-core";
import { Box, DataGrid, DateField, debounce, DeleteButton, FormControl, GridColumns, GridToolbar, InputLabel, List, TextField, useDataGrid } from "@pankod/refine-mui";
import { ICatalog } from "interfaces";

export const CatalogList: React.FC = (props: any) => {
  const t = useTranslate();

  const { dataGridProps } = useDataGrid<ICatalog>();

  const filterOptions = [
    {
      label: 'Es igual a',
      value: 'equals',
      getApplyFilterFn: (filterItem: any) => {
        console.log(filterItem)
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value === filterItem.value;
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
    {
      label: 'Contiene',
      value: 'contains',
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem.value) {
          return null;
        }
        return ({ value }: any) => value.includes(filterItem.value);
      },
      InputComponent: (props: any) => {
        const [inputValue, setInputValue] = useState(props.item.value || '');
        const inputRef = useRef<HTMLInputElement>(null);

        // Debounce para aplicar el valor después de que el usuario termine de escribir
        const debouncedApplyValue = useRef(
          debounce((value) => {
            props.applyValue({ ...props.item, value });
          }, 500)
        ).current;

        const handleChange = (event: any) => {
          const value = event.target.value;
          setInputValue(value);
          debouncedApplyValue(value); // Aplicamos el filtro con debounce
        };

        // Mantener el foco en el input tras cada render
        useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        return (
          <FormControl fullWidth variant="standard">
            <InputLabel shrink htmlFor="filter-input">
              Valor
            </InputLabel>
            <TextField
              style={{marginTop: '16px'}}
              inputRef={inputRef} // Usamos el ref para manejar el foco
              id="filter-input"
              value={inputValue}
              onChange={handleChange}
              placeholder="Filtrar"
              fullWidth
              variant="standard"
            />
          </FormControl>
        );
      },
    },
  ]

  const columns = React.useMemo<GridColumns<ICatalog>>(
    () => [
      {
        field: "value",
        headerName: t("catalogs.fields.value"),
        renderCell: function render({ row }) {
          return <p>{row.value}</p>;
        },
        minWidth: 100,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "updatedAt",
        headerName: t("catalogs.fields.updatedAt"),
        renderCell: function render({ row }) {
          return <DateField value={row.updatedAt} format="DD/MM/YYYY H:mm" />;
        },
        minWidth: 50,
        flex: 0.2,
        filterable: true,
        filterOperators: filterOptions
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "row" }}
              autoComplete="off"
            >
              {/*<EditButton hideText recordItemId={row.id} />*/}
              <DeleteButton hideText recordItemId={row.id} />
            </Box>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid 
      {...dataGridProps} 
      columns={columns} 
      components={{
        Toolbar: GridToolbar,
      }}
      autoHeight />
    </List>
  );
};